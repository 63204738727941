import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModificationService } from "@app/shared/services/modification.service";
import { Subscription } from "rxjs";
import { ModificationDataModel } from "../../utils/model";
import { AuthStore } from "@app/core/auth";

@Component({
  selector: "app-new-modification",
  templateUrl: "./new-modification.component.html",
  styleUrls: ["./new-modification.component.scss"],
})
export class NewModificationComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public associationsList: any[] = [];
  public selectedAssociation: any;
  public unitsList: any[] = [];
  public minorType: ModificationDataModel[] = [];
  public moderateType: ModificationDataModel[] = [];
  public majorType: ModificationDataModel[] = [];
  public user = inject(AuthStore).getAuthUser();
  public userRole: string | undefined = "";
  public files: File[] = [];

  private subscribers: Subscription[] = [];

  constructor(
    private modificationsService: ModificationService,
  ) {
  }

  ngOnInit(): void {
    this.userRole = this.user?.role;

    this.initForm();
    this.initValidator();
    this.getAssociationsService();
    this.getModificationTypeService();
  }

  ngOnChanges(): void {}

  private initForm(): void {
    this.form = new FormGroup({
      startDate: new FormControl(null, Validators.required),
      completionDate: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      unitId: new FormControl(null, Validators.required),
      // uploadFile: new FormControl(null, Validators.required),

      // tenant
      description: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),

      // manager
      propertyId: new FormControl(null, Validators.required),
      account: new FormControl(null, Validators.required),
      info: new FormControl(null),
    });
  }

  private initValidator(): void {
    if (this.userRole === "manager") {
      this.form.updateValueAndValidity();
      return;
    }

    this.form.get("association")?.clearValidators();
    this.form.get("account")?.clearValidators();
    this.form.updateValueAndValidity();
  }

  public selectedAssociationChange(data: any): void {
    this.selectedAssociation = data;
    this.getUnitsService(data);
  }

  private getUnitsService(associationId: string): void {
    const subs = this.modificationsService
      .getUnitsByAssociationId(associationId)
      .subscribe((resp) => {
        this.unitsList = resp.units;
      });
    this.subscribers.push(subs);
  }
  private getModificationTypeService(): void {
    const subs = this.modificationsService.getCategories().subscribe((resp) => {
      this.minorType = resp.minor;
      this.moderateType = resp.moderate;
      this.majorType = resp.major;
    });

    this.subscribers.push(subs);
  }

  public openFileUpload(id: string): void {
    document?.getElementById(id)?.click();
  }

  public selectedFile(event: any): void {
    const File = (event.target as HTMLInputElement).files;
    this.files.push(File![0]);
  }

  public deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  public borderColorChange(data: ModificationDataModel): void {
    [...this.minorType, ...this.moderateType, ...this.majorType].forEach(
      (menus: ModificationDataModel) => {
        menus.focus = menus.id === data.id;
      },
    );

    this.form.get("category")?.setValue(data.id);
  }

  private getAssociationsService(): void {
    const subs = this.modificationsService
      .getAssociations()
      .subscribe((resp) => {
        this.associationsList = resp.properties;
      });
    this.subscribers.push(subs);
  }
  // private closeDialog() {
  //   this.dialogRef.close();
  // }

  ngOnDestroy(): void {
    this.subscribers.forEach((each) => each.unsubscribe());
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    // if (!this.form.valid) {
    //
    //   return;
    // }

    const request = this.modificationsService.createModification({
      propertyId: this.form.controls["propertyId"].value,
      categoryId: this.form.controls["category"].value,
      customerId: this.form.controls["customerId"].value,
      startDate: this.form.controls["startDate"].value,
      completionDate: this.form.controls["completionDate"].value,
      description: this.form.controls["description"].value,
      info: this.form.controls["info"].value,
      files: this.files ?? [],
    });

    request.subscribe(() => {});

    // this.closeDialog();
  }
}
